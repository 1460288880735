import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import { BrowserRouter as Router, Rout } from 'react-router-dom';
import './App.scss';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

import { AzureAD, AuthenticationState } from 'react-aad-msal';
import GetAccessTokenButton from './getTokenButton';
// Import the provider created in a different file
import { authProvider } from './authProvider';

 import BarcodeReader from 'react-barcode-reader'
//import Barcode from "react-native-barcode-builder";
import {doRequest} from './api';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

 
class App extends Component {
	
	constructor(props) {
    super(props);
    this.state = {
      loginToken:null,
	  acctData:null,
	  account:null,
	  result: 'No result',
    };
	window.rootApp = this;
	window.rootApp.doReq = doRequest;
	this.didloginCallback = this.didloginCallback.bind(this);
	this.doLogout = this.doLogout.bind(this);
	this.showLogin = this.showLogin.bind(this);
	this.finishLogin = this.finishLogin.bind(this);
	this.getProfilePic = this.getProfilePic.bind(this);
	this.handleScan = this.handleScan.bind(this)
	if(localStorage.getItem('token') && localStorage.getItem('tokenExpiry'))
	{
		var token = localStorage.getItem('token');
		var data = {};
		data.expireDate = localStorage.getItem('tokenExpiry');
		data.displayName = localStorage.getItem('Name');
		data.givenName = localStorage.getItem('firstName');
		data.mail = localStorage.getItem('mail');
		
		this.state.loginToken = token;
		this.state.acctData = data;
		
	}
	
  }
  
  handleScan(data){
    this.setState({
      result: data,
    })
  }
  handleError(err){
    console.error(err)
  }
	
  didloginCallback(error,authData)
  {
	  console.log("LOGIN SUCCESSFUL");
	  console.log(error);
	  console.log(authData);
	  localStorage.setItem('token',authData.accessToken);
	  localStorage.setItem('tokenExpiry',authData.expiresOn);
	  localStorage.setItem('Name',authData.displayName);
	  localStorage.setItem('mail',authData.mail);
	  localStorage.setItem('firstName',authData.givenName);
	  
		if (authData != null && authData.accessToken != null) {
			this.setState(state => ({ loginToken: authData.accessToken,acctData: authData }));
		}
    console.log(doRequest("https://graph.microsoft.com/v1.0/me"));
  }
  
  doLogout()
  {
	  localStorage.setItem('token',null);
	  localStorage.setItem('tokenExpiry',null);
	  localStorage.setItem('Name',null);
	  localStorage.setItem('mail',null);
	  localStorage.setItem('firstName',null);
	  this.setState(state => ({ loginToken: null,acctData: null }));
  }

  showLogin(login)
  {
	  		
		
	return(
                <div className="app flex-row align-items-center">
				 
                <Container>
				
                  <Row className="justify-content-center">
                    <Col md="8">
                      <CardGroup>
                        <Card className="p-4">
						<img src={'./jalogo.svg'} />
                          <CardBody>
                            <Form>
                              <h1>Login</h1>
                              <p className="text-muted">Sign In to your account - use @jandacloud.com</p>
                              <InputGroup className="mb-3">
							  <Button color="primary" onClick={login}><i className="cui-lock-unlocked"></i>  Login</Button>
                              </InputGroup>
                              <Row>
                                <Col xs="6">
                                </Col>
                                <Col xs="6" className="text-right">
                                </Col>
                              </Row>
                            </Form>
                          </CardBody>
                        </Card>
                      </CardGroup>
                    </Col>
                  </Row>
                </Container>
              </div>
	  )
  }
  
  finishLogin(accountInfo,logout)
  {
	  window.rootApp.callLogout = logout;
	  window.rootApp.account = accountInfo;
	  window.rootApp.authProvider = authProvider;
	  if(window.rootApp.token == null)
	  {
		  authProvider.getAccessToken().then(token => {
		  window.rootApp.token = token.accessToken;
		}).then( data => {this.getProfilePic()});
		
		  
	  }
	  
	
  }
  
  getProfilePic()
  {
	  var url = "https://graph.microsoft.com/v1.0/me/photo/$value";
	  
	  	fetch(url, {
		method: 'GET',
		headers: {
		  Authorization: 'Bearer ' + this.token,
		  'Content-Type': 'application/json',
			},
		  })
		.then(response => {
			 if (response.statusText === 'OK') {
			  return response.blob();  // OR return response.url
			  }
			 })
		.then(data => {if(data!=null){window.URL = window.URL || window.webkitURL; this.setState({ pic: window.URL.createObjectURL(data)})}});
	
  }

  render() {
	  
		return (
		/*<AzureAD provider={authProvider} forceLogin={false}>
		  {
			({login, logout, authenticationState, error, accountInfo}) => {
			  switch (authenticationState) {
				case AuthenticationState.Authenticated:
				this.finishLogin(accountInfo,logout);
				*//*<BarcodeReader
					  onError={this.handleError}
					  onScan={this.handleScan}
					  /><p>{this.state.result}</p>*/
					  <Router>
					  <React.Suspense fallback={loading()}>
						  <Route path='/' name="Home" component={DefaultLayout}/>
					  </React.Suspense>
					  </Router>/*
					</>
				  );
				case AuthenticationState.Unauthenticated:
				  return (
					<div>
					{this.showLogin(login)}
					</div>
				  );
				case AuthenticationState.InProgress:
				  return (<p>Authenticating...</p>);
			  }
			}
		  }
		  </AzureAD>
          */
          );
	}
   

}

export default App;
