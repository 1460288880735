import { authProvider } from './authProvider';
 
const request = async url => {
  const token = window.rootApp.token;
  fetch(url, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  }).then(function(response){
		  return response.json();
	  })
	  .then(data => console.log(data))
};

export function doRequest(url,aToken)
{
	//console.log(token);
	
	console.log(request(url));
}